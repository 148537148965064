<template>
  <table :class="`table--perweek table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Tijd</th>
        <th class="table__head--text">Itemnummer</th>
        <th class="table__head--text">Omschrijving</th>
        <th class="table__head--text">Klant</th>
        <th class="table__head--text">Kenteken</th>
        <th class="table__head--text">Aflevering</th>
        <th class="table__head--text">Werkopdrachten Int/Ext</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
         :style="{ 'background-color': item.afgeleverd == true ? '#81c784' : '' }"
      >
        <td class="table__cell--default">{{ item.Tijd }}</td>
        <td class="table__cell--default">
          <ImageHover :itemnummer="item.Itemnummer" :bu="table_style" />
        </td>
        <td class="table__cell--default">{{ item.Omschrijving }}</td>
        <td class="table__cell--default">{{ item.Klant }}</td>
        <td class="table__cell--default">{{ item.Kenteken }}</td>
        <td class="table__cell--default">{{ item.Aflevering }}</td>
        <td class="table__cell--default table__data--right">
          <span v-if="(item.IsOpenIntern == 1)" class="red"><strong>{{
            item.CountIntern
          }}</strong></span
          ><span v-else>{{ item.CountIntern }}</span> /
          <span v-if="(item.IsOpenExtern == 1)" class="red"><strong>{{
            item.CountExtern
          }}</strong></span
          ><span v-else>{{ item.CountExtern }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
  props: {
    table_data: [Object, Array],
    table_style: [Number, String]
  },
  components: { ImageHover },
};
</script>
