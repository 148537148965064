<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h3 class="text-center">Afleverplanning</h3>
    <hr>
    <Loading v-if="loading" />
    <div v-else class="flexbox spaceevenly">
      <div class="">
        <h5 class="text-center">Vandaag</h5>
        <VansPlanningTable :table_data="planning_today" :table_style="bu" />
      </div>
      <div class="">
        <h5 class="text-center">Morgen</h5>
        <VansPlanningTable :table_data="planning_tomorrow" :table_style="bu"/>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import VansPlanningTable from "@/components/VansPlanningTable.vue";

export default {
  props: ["bu"],
  components: { Loading, VansPlanningTable },
  data: () => ({
    planning_today: null,
    planning_tomorrow: null,
    loading: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu)
    }
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`planning/${bu}`, "GET").then(
        ({ planning_today, planning_tomorrow }) => {
          this.planning_today = planning_today;
          this.planning_tomorrow = planning_tomorrow;
          this.loading = false;
        }
      );
    },
  },
};
</script>
